/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.11.1/dist/jquery.min.js
 * - /npm/bootstrap-select@1.13.18/dist/js/bootstrap-select.min.js
 * - /npm/bootstrap-select@1.13.18/dist/js/i18n/defaults-pt_PT.min.js
 * - /npm/jquery-mask-plugin@1.14.16/dist/jquery.mask.min.js
 * - /npm/js-search@2.0.0/dist/umd/js-search.min.js
 * - /npm/bootbox@4.3.0/bootbox.min.js
 * - /npm/swiper@4.1.0/dist/js/swiper.min.js
 * - /npm/jquery-price-format@0.0.1/jquery.priceformat.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
